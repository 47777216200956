import React, { Component } from "react";
import {
  BrowserRouter as Router,
  // Route,Switch
} from "react-router-dom";
import "./App.scss";
import "./style/customstyle.css";
import RouteMap from "./route";
import { createBrowserHistory } from "history";

function getFaviconEl() {
  return document.getElementById("favicon");
}
function getAppFavicon() {
  return document.getElementById("fav");
}
export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});
function getAppTitle() {
  return document.getElementById("companyname");
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: "",
      title: "",
    };
  }

  componentDidMount() {
    fetch(
      // "https://backoffice.infinitemlmsoftware.com/backoffice/api/common/app_info", {
      "https://bellytoxlifestyle.com/soft/backoffice/api/common/app_info",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": "3c7097ca-7868-440c-800d-729abdf12765",
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log("company_ifo============",json)
        this.setState({
          logo: json.data.company_info.favicon,
          title: json.data.company_info.company_name,
        });
        this.handleGoogle();
      });
  }
  handleGoogle() {
    const favicon = getFaviconEl(); // Accessing favicon element
    const fav = getAppFavicon();
    favicon.href =
      "https://backoffice.infinitemlmsoftware.com/uploads/images/logos/" +
      this.state.logo;
    fav.href =
      "https://backoffice.infinitemlmsoftware.com/uploads/images/logos/" +
      this.state.logo;
  }
  handleApptitle() {
    getAppTitle();
    document.title = this.state.title;
  }
  render() {
    return (
      <Router>
        <RouteMap />
      </Router>
    );
  }
}
export default App;
