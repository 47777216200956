import React, { useState, useEffect } from "react";
import style from "./dashboard.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TilesFilter from "./TilesFilter";
import Chart from "react-google-charts";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { IMG_URL } from "../../../configuration/apiconfig";
import { Row, Col } from "react-bootstrap";

// const brdercontent = {
//   marginBottom: "20px",
//   paddingBottom: "30px",
//   borderBottom: "1px #e5e5e5 solid",
// };
function getOrdinalSuffix(number) {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = number % 100;
  return `${number}${
    suffixes[(remainder - 20) % 10] || suffixes[remainder] || "th"
  }`;
}

const WeeklyPerformaceData = React.memo(({ weekly_performance_data = [] }) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const Lang = useSelector((state) => state.Lang);
  const { t } = useTranslation();

  console.log(weekly_performance_data, "weekly_performance_data");
  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(217, 5, 95, 0.94)",
          padding: "10px",
          borderRadius: "10px",
          height: "95%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className={`${
            currentTheme == "theme-dark"
              ? `${style.dark_mode_dashboard_pannel_box_weekly}`
              : `${style.dashboard_pannel_box_weekly}`
          }`}
          style={{ borderColor: "#bf1f62" }}
        >
          <div
            className={`${
              currentTheme == "theme-dark"
                ? `${style.dark_mode_dashboard_newsec_head}`
                : `${style.dashboard_newsec_head}`
            }`}
            style={{ color: "#035f7a", FontWeight: "bold", fontSize: "24px" }}
          >
            <strong> {t("Common.MonthlyPerformaceData")}</strong>
          </div>
          <div className={style.dashboard_boxs_listing_sponcer_sec}>
            <div
              className={style.dashboard_newsec_grph_sec}
              style={{
                overflow: "hidden",
                padding: "2rem",
                // display: "flex",
                // gap: "1em",
              }}
            >
              <div className="row">
                {weekly_performance_data.length > 0 ? (
                  weekly_performance_data.map((obj, index) => {
                    return (
                      <div
                        key={index}
                        class="col-md-4"
                        // style={{ display: "flex",width: "850px", }}
                      >
                        <div
                        // class="col-md-4"
                        // style={{ width: "350px", }}
                        >
                          <div
                            className={style.list_boxes_main}
                            style={{
                              backgroundColor: "#ece9e9",
                              padding: "15px",
                              borderRadius: "5px",
                              boxShadow:
                                "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              height: "95%",
                            }}
                          >
                            <div
                              className={`${
                                currentTheme == "theme-dark"
                                  ? `${style.dark_mode_earning_tabs_name}`
                                  : `${style.earning_tabs_name}`
                              }`}
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                textAlign: "center",
                                fontSize: "19px",
                              }}
                            >
                              Week {index + 1} Performance
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "stretch",
                                  paddingRight: "15px",
                                  paddingTop:"5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{t("Common.purchase")}</span>
                                <span style={{ alignSelf: "flex-end" }}>
                                  {obj.purchase === "yes" ? (
                                    <div className={style.checkmark}></div>
                                  ) : (
                                    <div className={style.crossmark}>
                                      <div className={style.line}></div>
                                      <div
                                        className={`${style.line} ${style.horizontal}`}
                                      ></div>
                                    </div>
                                  )}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "stretch",
                                  paddingRight: "15px",
                                  paddingTop:"5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{t("Common.client_group")}</span>
                                <span style={{ alignSelf: "flex-end" }}>
                                  {obj.client_group === "yes" ? (
                                    <div className={style.checkmark}></div>
                                  ) : (
                                    <div className={style.crossmark}>
                                      <div className={style.line}></div>
                                      <div
                                        className={`${style.line} ${style.horizontal}`}
                                      ></div>
                                    </div>
                                  )}
                                </span>
                              </div>
                              {/* 3rd one*/}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "stretch",
                                  paddingRight: "15px",
                                  paddingTop:"5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{t("Common.entire_group")}</span>
                                <span style={{ alignSelf: "flex-end" }}>
                                  {obj.entire_group === "yes" ? (
                                    <div className={style.checkmark}></div>
                                  ) : (
                                    <div className={style.crossmark}>
                                      <div className={style.line}></div>
                                      <div
                                        className={`${style.line} ${style.horizontal}`}
                                      ></div>
                                    </div>
                                  )}
                                </span>
                              </div>

                              {/* 4th one*/}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "stretch",
                                  paddingRight: "15px",
                                  paddingTop:"5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{t("Common.testimony")}</span>
                                <span style={{ alignSelf: "flex-end" }}>
                                  {obj.testimony === "yes" ? (
                                    <div className={style.checkmark}></div>
                                  ) : (
                                    <div className={style.crossmark}>
                                      <div className={style.line}></div>
                                      <div
                                        className={`${style.line} ${style.horizontal}`}
                                      ></div>
                                    </div>
                                  )}
                                </span>
                              </div>

                              {/*5th one */}
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "stretch",
                                  paddingRight: "15px",
                                  paddingTop:"5px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span>{t("Common.mentoring")}</span>
                                <span style={{ alignSelf: "flex-end" }}>
                                  {obj.mentoring === "yes" ? (
                                    <div className={style.checkmark}></div>
                                  ) : (
                                    <div className={style.crossmark}>
                                      <div className={style.line}></div>
                                      <div
                                        className={`${style.line} ${style.horizontal}`}
                                      ></div>
                                    </div>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className={`${style.NodataImage}`}>
                    <center>
                      <img
                        src={IMG_URL + "logos/no-datas-found.png"}
                        alt="nodata"
                        style={{ width: "50%" }}
                      />
                    </center>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default WeeklyPerformaceData;
