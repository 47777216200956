import React from 'react';
import style from './invoice.module.scss';
import DatePickers from './DateRangePicker.jsx';
import {Button, Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import ReportTable from './ReportTable'
import {useSelector} from 'react-redux';
import _ from "lodash";
import {CurrencyDecimalFormat} from '../../helper';

function Report(props) {

    const {t} = useTranslation();
    const Currency = useSelector(state=>state.curr)
   

    return (
        <div className={style.reportContiner}>

                    <div class="seller_filterSection__3YQ45 ">
                        <DatePickers
                        {...props.filter}
                        change={props.changeDate}
                        />
                        <Button  style={{marginLeft:'10px'}} className={`${style.btnPrimary}`} onClick={props.submitFilter} >
                            {t('Button.search')}
                        </Button>
                        <Button  style={{marginLeft:'10px'}} className={`${style.btnInfo}`} onClick={props.resetButton}>
                            {t('Button.reset')}
                        </Button>
                    </div>



            <Card className={style.panel}>
                <Card.Body className={style.panelBody}>
                   
                        
                        <div>
                   
                    {/* <span    style={{float:'right'}} className={style.exportButton}>
                        <Button  style={{margin:'10px'}} onClick={()=>{exportData('excel')}}>
                            <i className="fa fa-file-excel-o"></i>
                            {t('Button.excel')}
                        </Button>
                        <Button  style={{margin:'10px'}} onClick={()=>{exportData('csv')}}>
                            <i className="fa fa-file-text-o"></i>
                            {t('Button.CSV')}
                        </Button>
                        <ReactToPrint
          //trigger={() => <Button><i className="fa fa-print"></i>{t('Button.print')}</Button>}
          trigger={() => <Button style={{margin:'10px'}}><i class="fa fa-print"></i>{t('Button.print')}</Button>}
          content={() => componentRef.current}
        />
      </span> */}
      <div style={{ display: "none" }} >
      {/* <PurchaseReportPrintView ref = {componentRef} {...props.report}  /> */}
      </div>
  
                    </div>
                    <div className={style.dataTalbes}>
                        <ReportTable 
                            {...props.report}
                            paginationchange={props.paginationchange}
                        />
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Report
