import {React,useEffect, useState} from 'react';
import style from './auth.module.scss';
import {IMG_URL} from '../../../configuration/apiconfig';
import AppInfoservice from '../../../service/common/Appinfo';

const Brand = () => {
    const [logo,setLogo]=useState('')
    const service = new AppInfoservice();
    

    useEffect(()=>{
        service.Appinfo().then((res)=>{
            console.log("company info response",res
            )
            if(res.status){
              setLogo(res.data.company_info.favicon)
            }else{
                
            }
        })  
    },[])
    console.log("logooooo",logo)

    return (
        
        <div className={`d-block mt-2 ${style.loginNavBrand}`}>
            <img src={"https://bellytoxlifestyle.com/soft/uploads/images/logos/"+logo} alt="" className={style.loginNavBrandImg}/>
        </div>
    );
};

export default Brand; 