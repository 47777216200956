import React from 'react';
import DataTable from "react-data-table-component";
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import "react-data-table-component-extensions/dist/index.css";
import {CurrencyDecimalFormat} from '../../helper';
import style from './invoice.module.scss';

function ReportTable(props) {
    
    const {t} = useTranslation();
    const Currency = useSelector(state=>state.curr)
    const columns = [
        {
          name: t('Common.Orderid').toUpperCase(),
          selector: row => row.order_id,
        //   width : "30%"
        },
        {
            name: t('Common.Meamber_name').toUpperCase(),
            selector: row => row.full_name,
            cell: (row) => <span>{row.full_name}</span>
          },
        {
            name: t('Common.totalAmount').toUpperCase(),
            selector: row => row.total,
            cell: (row) => <span className={`${style.primaryBadge} ${style.badge}`}>
                {Currency.currentCurr} 
                {CurrencyDecimalFormat(row.total*Currency.value,Currency.precision)}
                </span>
        },
        {
            name: t('Common.purchaseDate').toUpperCase(),
            selector: row => row.date
        },
        {
          name: t('Common.paymentStatus').toUpperCase(),
          selector: row => row.payment_method,
          cell: (row) => <span>{row.status}</span>
        },
        
          {
          name: t('Common.productData').toUpperCase(),
          selector: row => row.product_data,
          cell: (row) => <span>{row.product_data}</span>
        }
       
      ];    
    const handlePerRowsChange = (perPage, inPage) => {
        props.paginationchange(
            inPage,
            perPage
        )
    };
    const handlePageChange = inPage => {
        props.paginationchange(
            inPage,
            props.perPage
        )
    };
    return (
        <div>
            <DataTable
                    columns={columns}
                    data={props.data}
                    highlightOnHover
                    progressPending={props.pending}
                    progressComponent={<div>Loading</div>}
                    persistTableHead
                    noHeader
                    pagination
                    paginationServer
                    responsive
                    paginationTotalRows={props.totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noDataComponent={t('Common.noDataRecordsToDisplay')}
                    // onSort={handleOnSort}
                    />
        </div>
    )
}

export default ReportTable
