// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://demo5.iossmlm.com/mlm_12.8/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "https://demo5.iossmlm.com/mlm_12.8/uploads/images/";
// const BASE = "https://demo5.iossmlm.com/mlm_12.8";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE
// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://checkout.demo5.iossmlm.com/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://checkout.demo5.iossmlm.com/uploads/images/";
// const BASE = "http://checkout.demo5.iossmlm.com/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE
// }

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://159.89.225.72:1000/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://159.89.225.72:1000/uploads/images/";
// const BASE = "http://159.89.225.72:1000";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE
// }
// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://backoffice.infinitemlmsoftware.com/backoffice/api/";
// const IMG_URL = "https://backoffice.infinitemlmsoftware.com/uploads/images/";
// const BASE = "https://backoffice.infinitemlmsoftware.com";
// const API_KEY =localStorage.getItem('apiKey');
// const DEFAULT_KEY = 'a201cb4c-0fa2-e8bae44e-aee1-3bfceb9a5fc5'
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//    DEFAULT_KEY

// }
//local

// import companyName from "../store/mobxStore/companyName";
// const COMPANY_NAME = companyName.companyName ?? "Company Name";
// const BASE_URL = "http://192.168.10.52/tendai-nyangari/backoffice/api/";
// // const BASE_URL = "http://mlmadmin.demo5.iossmlm.com/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL =
//   "http://192.168.10.52/tendai-nyangari/backoffice/uploads/images/";
// const BASE = "http://192.168.10.52/tendai-nyangari/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// // const DEFAULT_KEY = "14869ac2-d19b-497b-b0bb-4845393e11f9";
// const DEFAULT_KEY = "3c7097ca-7868-440c-800d-729abdf12765";
// console.log(localStorage);
// export { COMPANY_NAME, BASE_URL, IMG_URL, API_KEY, BASE, DEFAULT_KEY };

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "http://192.168.7.74/garage/work-copy/mlm/backoffice/api/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
// const IMG_URL = "http://192.168.7.74/garage/work-copy/mlm/uploads/images/";
// const BASE = "http://192.168.7.74/garage/work-copy/mlm/";
// // export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// // export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
// const API_KEY =localStorage.getItem('apiKey'); //binaryaddon;
// const DEFAULT_KEY = 'bfd6eb01-9991-4380-88bc-c42852a8dbcc'
// //const DEFAULT_KEY = '14963'
// console.log(localStorage);
// export {
//     COMPANY_NAME,
//     BASE_URL,
//     IMG_URL,
//     API_KEY,
//     BASE,
//     DEFAULT_KEY
// }

//LIVE
import companyName from "../store/mobxStore/companyName";
const COMPANY_NAME = companyName.companyName ?? "Company Name";
const BASE_URL = "https://bellytoxlifestyle.com/soft/backoffice/api/";
// const BASE_URL = "http://mlmadmin.demo5.iossmlm.com/api/";
// export const IMG_URL = "https://demo5.iossmlm.com/12.0.7/";
const IMG_URL = "https://bellytoxlifestyle.com/soft/uploads/images/";
const BASE = "https://bellytoxlifestyle.com/soft/backoffice/api/";
// export const IMG_URL = "https://demo5.iossmlm.com/12.0.6/";
// export const API_KEY ="4f665866-5ba7-4d5b-82b1-956b854fd986"; //demo 5
const API_KEY = localStorage.getItem("apiKey"); //binaryaddon;
// const DEFAULT_KEY = "14869ac2-d19b-497b-b0bb-4845393e11f9";
const DEFAULT_KEY = "3c7097ca-7868-440c-800d-729abdf12765";
// console.log(localStorage);
export { COMPANY_NAME, BASE_URL, IMG_URL, API_KEY, BASE, DEFAULT_KEY };
